module.exports = {
  mainnet: {
    terra1e6mq63y64zcxz8xyu5van4tgkhemj3r86yvgu4:{
      protocol: "USDC",
      name: "USDC",
      symbol: "soUSDC",
      token: "terra1e6mq63y64zcxz8xyu5van4tgkhemj3r86yvgu4",
      icon:"https://jup.ag/_next/image?url=https%3A%2F%2Fraw.githubusercontent.com%2Fsolana-labs%2Ftoken-list%2Fmain%2Fassets%2Fmainnet%2FEPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v%2Flogo.png&w=48&q=75",
      decimals: 6
    },
    terra13zaagrrrxj47qjwczsczujlvnnntde7fdt0mau:{
      protocol: "Prism",
      name: "Prism cLuna Token",
      symbol: "cLuna",
      token: "terra13zaagrrrxj47qjwczsczujlvnnntde7fdt0mau",
      icon:"https://extraterra-assets.s3.us-east-2.amazonaws.com/images/SVG/cLUNA.svg",
      decimals: 6
    },
    terra188w26t95tf4dz77raftme8p75rggatxjxfeknw:{
      protocol: "Kujira sKUJI Token",
      name: "Kujira sKUJI Token",
      symbol: "sKUJI",
      token: "terra188w26t95tf4dz77raftme8p75rggatxjxfeknw",
      icon:"https://app.astroport.fi/tokens/skuji.png",
      decimals: 6
    },
    terra1hj8de24c3yqvcsv9r8chr03fzwsak3hgd8gv3m:{
      protocol: "Wrapped AVAX",
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      token: "terra1hj8de24c3yqvcsv9r8chr03fzwsak3hgd8gv3m",
      icon: "https://app.astroport.fi/tokens/avax.png",
      decimals: 6
    },
    terra190tqwgqx7s8qrknz6kckct7v607cu068gfujpk:{
      protocol: "Wrapped SOL",
      symbol: "wsSOL",
      name: "wsSOL",
      token: "terra190tqwgqx7s8qrknz6kckct7v607cu068gfujpk",
      icon: "https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/SOL_wh.png",
      decimals: 6
    },
    terra14v9wrjs55qsn9lkvylsqela3w2ytwxzkycqzcr:{
      protocol: "SAYVE",
      symbol: "SAYVE",
      name: "SAYVE",
      token: "terra14v9wrjs55qsn9lkvylsqela3w2ytwxzkycqzcr",
      icon: "	https://extraterra-assets.s3.us-east-2.amazonaws.com/images/SAYVE100.png",
      decimals: 6
    },
    terra12hgwnpupflfpuual532wgrxu2gjp0tcagzgx4n: {
      protocol: "Mars",
      symbol: "MARS",
      name: "Mars",
      token: "terra12hgwnpupflfpuual532wgrxu2gjp0tcagzgx4n",
      icon: "https://marsprotocol.io/mars_logo_colored.svg",
      decimals: 6
    },
    terra1mt2ytlrxhvd5c4d4fshxxs3zcus3fkdmuv4mk2:{
      protocol: "Brokkr Brotocol",
      symbol: "BRO",
      token: "terra1mt2ytlrxhvd5c4d4fshxxs3zcus3fkdmuv4mk2",
      icon: "https://brokkr.finance/img/brokkr_full_logo.b2827905.svg"
    },
    uusd: {
        protocol: "Native",
        symbol: "UST",
        token: "uusd",
        icon: 'https://assets.terra.money/icon/60/UST.png'
    },
    uluna: {
        protocol: "Native",
        symbol: "LUNA",
        token: "uluna",
        icon: 'https://assets.terra.money/icon/60/Luna.png'
    },
    terra100yeqvww74h4yaejj6h733thgcafdaukjtw397: {
        protocol: "Apollo DAO Token",
        symbol: "APOLLO",
        token: "terra100yeqvww74h4yaejj6h733thgcafdaukjtw397",
        icon: "https://extraterra-assets.s3.us-east-2.amazonaws.com/images/APOLLO60.png"
    },
    terra1kc87mu460fwkqte29rquh4hc20m54fxwtsx7gp: {
      protocol: "Anchor",
      symbol: "bLUNA",
      token: "terra1kc87mu460fwkqte29rquh4hc20m54fxwtsx7gp",
      icon: "https://whitelist.anchorprotocol.com/logo/bLUNA.png"
    },
    terra1dzhzukyezv0etz22ud940z7adyv7xgcjkahuun: {
      protocol: "Anchor",
      symbol: "bETH",
      token: "terra1dzhzukyezv0etz22ud940z7adyv7xgcjkahuun",
      icon: "https://whitelist.anchorprotocol.com/logo/bETH.png"
    },
    terra1hzh9vpxhsk8253se0vv5jj6etdvxu3nv8z07zu: {
      protocol: "Anchor",
      symbol: "aUST",
      token: "terra1hzh9vpxhsk8253se0vv5jj6etdvxu3nv8z07zu",
      icon: "https://whitelist.anchorprotocol.com/logo/aUST.png"
    },
    terra14z56l0fp2lsf86zy3hty2z47ezkhnthtr9yq76: {
      protocol: "Anchor",
      symbol: "ANC",
      token: "terra14z56l0fp2lsf86zy3hty2z47ezkhnthtr9yq76",
      icon: "https://whitelist.anchorprotocol.com/logo/ANC.png"
    },
    terra15gwkyepfc6xgca5t5zefzwy42uts8l2m4g40k6: {
      protocol: "Mirror",
      symbol: "MIR",
      name: "Mirror",
      token: "terra15gwkyepfc6xgca5t5zefzwy42uts8l2m4g40k6",
      icon: "https://whitelist.mirror.finance/icon/MIR.png"
    },
    terra1vxtwu4ehgzz77mnfwrntyrmgl64qjs75mpwqaz: {
      protocol: "Mirror",
      symbol: "mAAPL",
      name: "Apple Inc.",
      token: "terra1vxtwu4ehgzz77mnfwrntyrmgl64qjs75mpwqaz",
      icon: "https://whitelist.mirror.finance/icon/AAPL.png"
    },
    terra1g4x2pzmkc9z3mseewxf758rllg08z3797xly0n: {
      protocol: "Mirror",
      symbol: "mABNB",
      name: "Airbnb Inc.",
      token: "terra1g4x2pzmkc9z3mseewxf758rllg08z3797xly0n",
      icon: "https://whitelist.mirror.finance/icon/ABNB.png"
    },
    terra1qelfthdanju7wavc5tq0k5r0rhsyzyyrsn09qy: {
      protocol: "Mirror",
      symbol: "mAMC",
      name: "AMC Entertainment Holdings Inc.",
      token: "terra1qelfthdanju7wavc5tq0k5r0rhsyzyyrsn09qy",
      icon: "https://whitelist.mirror.finance/icon/AMC.png"
    },
    terra18ej5nsuu867fkx4tuy2aglpvqjrkcrjjslap3z: {
      protocol: "Mirror",
      symbol: "mAMD",
      name: "Advanced Micro Devices, Inc.",
      token: "terra18ej5nsuu867fkx4tuy2aglpvqjrkcrjjslap3z",
      icon: "https://whitelist.mirror.finance/icon/AMD.png"
    },
    terra165nd2qmrtszehcfrntlplzern7zl4ahtlhd5t2: {
      protocol: "Mirror",
      symbol: "mAMZN",
      name: "Amazon.com, Inc.",
      token: "terra165nd2qmrtszehcfrntlplzern7zl4ahtlhd5t2",
      icon: "https://whitelist.mirror.finance/icon/AMZN.png"
    },
    terra1qqfx5jph0rsmkur2zgzyqnfucra45rtjae5vh6: {
      protocol: "Mirror",
      symbol: "mARKK",
      name: "ARK Innovation ETF",
      token: "terra1qqfx5jph0rsmkur2zgzyqnfucra45rtjae5vh6",
      icon: "https://whitelist.mirror.finance/icon/ARKK.png"
    },
    terra1w7zgkcyt7y4zpct9dw8mw362ywvdlydnum2awa: {
      protocol: "Mirror",
      symbol: "mBABA",
      name: "Alibaba Group Holding Limited",
      token: "terra1w7zgkcyt7y4zpct9dw8mw362ywvdlydnum2awa",
      icon: "https://whitelist.mirror.finance/icon/BABA.png"
    },
    terra1rhhvx8nzfrx5fufkuft06q5marfkucdqwq5sjw: {
      protocol: "Mirror",
      symbol: "mBTC",
      name: "Bitcoin",
      token: "terra1rhhvx8nzfrx5fufkuft06q5marfkucdqwq5sjw",
      icon: "https://whitelist.mirror.finance/icon/BTC.png"
    },
    terra18wayjpyq28gd970qzgjfmsjj7dmgdk039duhph: {
      protocol: "Mirror",
      symbol: "mCOIN",
      name: "Coinbase Global, Inc.",
      token: "terra18wayjpyq28gd970qzgjfmsjj7dmgdk039duhph",
      icon: "https://whitelist.mirror.finance/icon/COIN.png"
    },
    terra19ya4jpvjvvtggepvmmj6ftmwly3p7way0tt08r: {
      protocol: "Mirror",
      symbol: "mDOT",
      name: "Polkadot",
      token: "terra19ya4jpvjvvtggepvmmj6ftmwly3p7way0tt08r",
      icon: "https://whitelist.mirror.finance/icon/DOT.png"
    },
    terra1dk3g53js3034x4v5c3vavhj2738une880yu6kx: {
      protocol: "Mirror",
      symbol: "mETH",
      name: "Ether",
      token: "terra1dk3g53js3034x4v5c3vavhj2738une880yu6kx",
      icon: "https://whitelist.mirror.finance/icon/ETH.png"
    },
    terra1mqsjugsugfprn3cvgxsrr8akkvdxv2pzc74us7: {
      protocol: "Mirror",
      symbol: "mFB",
      name: "Facebook Inc.",
      token: "terra1mqsjugsugfprn3cvgxsrr8akkvdxv2pzc74us7",
      icon: "https://whitelist.mirror.finance/icon/FB.png"
    },
    terra1l5lrxtwd98ylfy09fn866au6dp76gu8ywnudls: {
      protocol: "Mirror",
      symbol: "mGLXY",
      name: "Galaxy Digital Holdings Ltd",
      token: "terra1l5lrxtwd98ylfy09fn866au6dp76gu8ywnudls",
      icon: "https://whitelist.mirror.finance/icon/GLXY.png"
    },
    terra1m6j6j9gw728n82k78s0j9kq8l5p6ne0xcc820p: {
      protocol: "Mirror",
      symbol: "mGME",
      name: "GameStop Corp",
      token: "terra1m6j6j9gw728n82k78s0j9kq8l5p6ne0xcc820p",
      icon: "https://whitelist.mirror.finance/icon/GME.png"
    },
    terra1h8arz2k547uvmpxctuwush3jzc8fun4s96qgwt: {
      protocol: "Mirror",
      symbol: "mGOOGL",
      name: "Alphabet Inc.",
      token: "terra1h8arz2k547uvmpxctuwush3jzc8fun4s96qgwt",
      icon: "https://whitelist.mirror.finance/icon/GOOGL.png"
    },
    terra137drsu8gce5thf6jr5mxlfghw36rpljt3zj73v: {
      protocol: "Mirror",
      symbol: "mGS",
      name: "Goldman Sachs Group Inc.",
      token: "terra137drsu8gce5thf6jr5mxlfghw36rpljt3zj73v",
      icon: "https://whitelist.mirror.finance/icon/GS.png"
    },
    terra15hp9pr8y4qsvqvxf3m4xeptlk7l8h60634gqec: {
      protocol: "Mirror",
      symbol: "mIAU (Delisted)",
      name: "iShares Gold Trust",
      token: "terra15hp9pr8y4qsvqvxf3m4xeptlk7l8h60634gqec",
      icon: "https://whitelist.mirror.finance/icon/IAU.png"
    },
    terra10h7ry7apm55h4ez502dqdv9gr53juu85nkd4aq: {
      protocol: "Mirror",
      symbol: "mIAU",
      name: "iShares Gold Trust",
      token: "terra10h7ry7apm55h4ez502dqdv9gr53juu85nkd4aq",
      icon: "https://whitelist.mirror.finance/icon/IAU.png"
    },
    terra1227ppwxxj3jxz8cfgq00jgnxqcny7ryenvkwj6: {
      protocol: "Mirror",
      symbol: "mMSFT",
      name: "Microsoft Corporation",
      token: "terra1227ppwxxj3jxz8cfgq00jgnxqcny7ryenvkwj6",
      icon: "https://whitelist.mirror.finance/icon/MSFT.png"
    },
    terra1jsxngqasf2zynj5kyh0tgq9mj3zksa5gk35j4k: {
      protocol: "Mirror",
      symbol: "mNFLX",
      name: "Netflix, Inc.",
      token: "terra1jsxngqasf2zynj5kyh0tgq9mj3zksa5gk35j4k",
      icon: "https://whitelist.mirror.finance/icon/NFLX.png"
    },
    terra1csk6tc7pdmpr782w527hwhez6gfv632tyf72cp: {
      protocol: "Mirror",
      symbol: "mQQQ",
      name: "Invesco QQQ Trust",
      token: "terra1csk6tc7pdmpr782w527hwhez6gfv632tyf72cp",
      icon: "https://whitelist.mirror.finance/icon/QQQ.png"
    },
    terra1kscs6uhrqwy6rx5kuw5lwpuqvm3t6j2d6uf2lp: {
      protocol: "Mirror",
      symbol: "mSLV",
      name: "iShares Silver Trust",
      token: "terra1kscs6uhrqwy6rx5kuw5lwpuqvm3t6j2d6uf2lp",
      icon: "https://whitelist.mirror.finance/icon/SLV.png"
    },
    terra1aa00lpfexyycedfg5k2p60l9djcmw0ue5l8fhc: {
      protocol: "Mirror",
      symbol: "mSPY",
      name: "SPDR S&P 500",
      token: "terra1aa00lpfexyycedfg5k2p60l9djcmw0ue5l8fhc",
      icon: "https://whitelist.mirror.finance/icon/SPY.png"
    },
    terra1u43zu5amjlsgty5j64445fr9yglhm53m576ugh: {
      protocol: "Mirror",
      symbol: "mSQ",
      name: "Square, Inc.",
      token: "terra1u43zu5amjlsgty5j64445fr9yglhm53m576ugh",
      icon: "https://whitelist.mirror.finance/icon/SQ.png"
    },
    terra14y5affaarufk3uscy2vr6pe6w6zqf2wpjzn5sh: {
      protocol: "Mirror",
      symbol: "mTSLA",
      name: "Tesla, Inc.",
      token: "terra14y5affaarufk3uscy2vr6pe6w6zqf2wpjzn5sh",
      icon: "https://whitelist.mirror.finance/icon/TSLA.png"
    },
    terra1cc3enj9qgchlrj34cnzhwuclc4vl2z3jl7tkqg: {
      protocol: "Mirror",
      symbol: "mTWTR",
      name: "Twitter, Inc.",
      token: "terra1cc3enj9qgchlrj34cnzhwuclc4vl2z3jl7tkqg",
      icon: "https://whitelist.mirror.finance/icon/TWTR.png"
    },
    terra1lvmx8fsagy70tv0fhmfzdw9h6s3sy4prz38ugf: {
      protocol: "Mirror",
      symbol: "mUSO",
      name: "United States Oil Fund, LP",
      token: "terra1lvmx8fsagy70tv0fhmfzdw9h6s3sy4prz38ugf",
      icon: "https://whitelist.mirror.finance/icon/USO.png"
    },
    terra19cmt6vzvhnnnfsmccaaxzy2uaj06zjktu6yzjx: {
      protocol: "Mirror",
      symbol: "mVIXY",
      name: "ProShares VIX Short-Term Futures ETF",
      token: "terra19cmt6vzvhnnnfsmccaaxzy2uaj06zjktu6yzjx",
      icon: "https://whitelist.mirror.finance/icon/VIXY.png"
    },
    terra1zp3a6q6q4953cz376906g5qfmxnlg77hx3te45: {
      protocol: "Mirror",
      symbol: "mVIXY (Delisted)",
      name: "ProShares VIX Short-Term Futures ETF",
      token: "terra1zp3a6q6q4953cz376906g5qfmxnlg77hx3te45",
      icon: "https://whitelist.mirror.finance/icon/VIXY.png"
    },
    terra1ez46kxtulsdv07538fh5ra5xj8l68mu8eg24vr: {
      protocol: "LoTerra",
      symbol: "LOTA",
      token: "terra1ez46kxtulsdv07538fh5ra5xj8l68mu8eg24vr",
      icon: "https://loterra.io/LOTA.png"
    },
    terra17jnhankdfl8vyzj6vejt7ag8uz0cjc9crkl2h7: {
      protocol: "Digipharm",
      symbol: "DPH",
      name: "Digipharm",
      token: "terra17jnhankdfl8vyzj6vejt7ag8uz0cjc9crkl2h7",
      icon: "https://digipharm.io/wp-content/uploads/2021/02/digipharm_etherscan_logo.png"
    },
    terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy: {
      protocol: "Pylon",
      symbol: "MINE",
      name: "Pylon MINE Token",
      token: "terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy",
      icon: "https://assets.pylon.rocks/logo/MINE.png"
    },
    terra1zsaswh926ey8qa5x4vj93kzzlfnef0pstuca0y: {
      protocol: "Pylon",
      symbol: "bPsiDP-24m",
      name: "Pylon bDP Token for Gateway Psi 24m Pool",
      token: "terra1zsaswh926ey8qa5x4vj93kzzlfnef0pstuca0y",
      icon: "https://assets.pylon.rocks/logo/bPsiDP.png"
    },
    terra1s5eczhe0h0jutf46re52x5z4r03c8hupacxmdr: {
      protocol: "Spectrum",
      symbol: "SPEC",
      name: "Spectrum Token",
      token: "terra1s5eczhe0h0jutf46re52x5z4r03c8hupacxmdr",
      icon: "https://terra.spec.finance/assets/SPEC.png"
    },
    terra1nef5jf6c7js9x6gkntlehgywvjlpytm7pcgkn4: {
      protocol: "LOOP",
      symbol: "LOOP",
      name: "LOOP token",
      token: "terra1nef5jf6c7js9x6gkntlehgywvjlpytm7pcgkn4",
      icon: "https://loop.markets/token/logo2.png"
    },
    terra1jx4lmmke2srcvpjeereetc9hgegp4g5j0p9r2q: {
      protocol: "LOOP",
      symbol: "LOOPR",
      name: "LOOPR token",
      token: "terra1jx4lmmke2srcvpjeereetc9hgegp4g5j0p9r2q",
      icon: "https://loop.markets/token/logo3.png"
    },
    terra13xujxcrc9dqft4p9a8ls0w3j0xnzm6y2uvve8n: {
      protocol: "StarTerra",
      symbol: "STT",
      name: "StarTerra Token",
      token: "terra13xujxcrc9dqft4p9a8ls0w3j0xnzm6y2uvve8n",
      icon: "https://starterra.io/assets/100x100_starterra.png"
    },
    terra19djkaepjjswucys4npd5ltaxgsntl7jf0xz7w6: {
      protocol: "TerraWorld",
      symbol: "TWD",
      name: "TWD Token",
      token: "terra19djkaepjjswucys4npd5ltaxgsntl7jf0xz7w6",
      icon: "https://terraoffice.world/twd_logo.png"
    },
    terra1vtr50tw0pgqpes34zqu60n554p9x4950wk8f63: {
      protocol: "Miaw Trader",
      symbol: "MIAW",
      name: "MIAW Token",
      token: "terra1vtr50tw0pgqpes34zqu60n554p9x4950wk8f63",
      icon: "https://www.miaw-trader.com/logo.png"
    },
    terra12897djskt9rge8dtmm86w654g7kzckkd698608: {
      protocol: "Nexus",
      symbol: "Psi",
      name: "Nexus Governance Token",
      token: "terra12897djskt9rge8dtmm86w654g7kzckkd698608",
      icon: "https://terra.nexusprotocol.app/assets/psi.png"
    },
    terra1dy9kmlm4anr92e42mrkjwzyvfqwz66un00rwr5: {
      protocol: "ValkyrieProtocol",
      symbol: "VKR",
      name: "VKR Token",
      token: "terra1dy9kmlm4anr92e42mrkjwzyvfqwz66un00rwr5",
      icon: "https://app.valkyrieprotocol.com/icon_vkr.png"
    },
    terra1mddcdx0ujx89f38gu7zspk2r2ffdl5enyz2u03: {
      protocol: "Orion Money",
      symbol: "ORION",
      name: "Orion Money Token",
      token: "terra1mddcdx0ujx89f38gu7zspk2r2ffdl5enyz2u03",
      icon: "https://orion.money/assets/ORION-LOGO-2.1-GREEN@256x256.png",
      decimals: 8
    },
    terra1r5506ckw5tfr3z52jwlek8vg9sn3yflrqrzfsc: {
      protocol: "TerraLand",
      symbol: "TLAND",
      name: "TerraLand Token",
      token: "terra1r5506ckw5tfr3z52jwlek8vg9sn3yflrqrzfsc",
      icon: "https://terralandio-site.s3.eu-central-1.amazonaws.com/TerraLand-logo-v1c-4x.png",
      decimals: 6
    },
    terra1php5m8a6qd68z02t3zpw4jv2pj4vgw4wz0t8mz: {
      protocol: "White Whale",
      symbol: "WHALE",
      name: "Whale Token",
      token: "terra1php5m8a6qd68z02t3zpw4jv2pj4vgw4wz0t8mz",
      icon: "https://www.whitewhale.money/tokenlogo.png",
      decimals: 6
    },
    terra14tl83xcwqjy0ken9peu4pjjuu755lrry2uy25r: {
      protocol: "Wormhole",
      symbol: "weWETH",
      name: "Wrapped Ether (Wormhole)",
      token: "terra14tl83xcwqjy0ken9peu4pjjuu755lrry2uy25r",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png",
      decimals: 8
    },
    terra1aa7upykmmqqc63l924l5qfap8mrmx5rfdm0v55: {
      protocol: "Wormhole",
      symbol: "weWBTC",
      name: "Wrapped BTC (Wormhole)",
      token: "terra1aa7upykmmqqc63l924l5qfap8mrmx5rfdm0v55",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh/logo.png",
      decimals: 8
    },
    terra190tqwgqx7s8qrknz6kckct7v607cu068gfujpk: {
      protocol: "Wormhole",
      symbol: "wsSOL",
      name: "Wrapped SOL (Wormhole)",
      token: "terra190tqwgqx7s8qrknz6kckct7v607cu068gfujpk",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
      decimals: 8
    },
    terra1dfasranqm4uyaz72r960umxy0w8t6zewqlnkuq: {
      protocol: "Wormhole",
      symbol: "weMATIC",
      name: "Matic Token (Wormhole)",
      token: "terra1dfasranqm4uyaz72r960umxy0w8t6zewqlnkuq",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7NNPWuZCNjZBfW5p6JvGsR8pUdsRpEdP1ZAhnoDwj7h/logo.png",
      decimals: 8
    },
    terra1cetg5wruw2wsdjp7j46rj44xdel00z006e9yg8: {
      protocol: "Wormhole",
      symbol: "wbWBNB",
      name: "Wrapped BNB (Wormhole)",
      token: "terra1cetg5wruw2wsdjp7j46rj44xdel00z006e9yg8",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa/logo.png",
      decimals: 8
    },
    terra1xvqlpjl2dxyel9qrp6qvtrg04xe3jh9cyxc6av: {
      protocol: "Wormhole",
      symbol: "wbCake",
      name: "PancakeSwap Token (Wormhole)",
      token: "terra1xvqlpjl2dxyel9qrp6qvtrg04xe3jh9cyxc6av",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J8LKx7pr9Zxh9nMhhT7X3EBmj5RzuhFrHKyJAe2F2i9S/logo.png",
      decimals: 8
    },
    terra12dfv3f0e6m22z6cnhfn3nxk2en3z3zeqy6ctym: {
      protocol: "Wormhole",
      symbol: "weLINK",
      name: "ChainLink Token (Wormhole)",
      token: "terra12dfv3f0e6m22z6cnhfn3nxk2en3z3zeqy6ctym",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX/logo.png",
      decimals: 8
    },
    terra1csvuzlf92nyemu6tv25h0l79etpe8hz3h5vn4a: {
      protocol: "Wormhole",
      symbol: "weSUSHI",
      name: "SushiToken (Wormhole)",
      token: "terra1csvuzlf92nyemu6tv25h0l79etpe8hz3h5vn4a",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj/logo.png",
      decimals: 8
    },
    terra1wyxkuy5jq545fn7xfn3enpvs5zg9f9dghf6gxf: {
      protocol: "Wormhole",
      symbol: "weUNI",
      name: "Uniswap (Wormhole)",
      token: "terra1wyxkuy5jq545fn7xfn3enpvs5zg9f9dghf6gxf",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36/logo.png",
      decimals: 8
    },
    terra1ce06wkrdm4vl6t0hvc0g86rsy27pu8yadg3dva: {
      protocol: "Wormhole",
      symbol: "weUSDT",
      name: "Tether USD (Wormhole)",
      token: "terra1ce06wkrdm4vl6t0hvc0g86rsy27pu8yadg3dva",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1/logo.png",
      decimals: 6
    },
    terra1pepwcav40nvj3kh60qqgrk8k07ydmc00xyat06: {
      protocol: "Wormhole",
      symbol: "weUSDC",
      name: "USD Coin (Wormhole)",
      token: "terra1pepwcav40nvj3kh60qqgrk8k07ydmc00xyat06",
      icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM/logo.png",
      decimals: 6
    },
    terra1xfsdgcemqwxp4hhnyk4rle6wr22sseq7j07dnn: {
      protocol: "Kujira",
      symbol: "KUJI",
      name: "Kujira Token",
      token: "terra1xfsdgcemqwxp4hhnyk4rle6wr22sseq7j07dnn",
      icon: "https://assets.kujira.app/kuji.png",
      decimals: 6
    },
    terra133chr09wu8sakfte5v7vd8qzq9vghtkv4tn0ur: {
      protocol: "Wormhole",
      symbol: "wewstETH",
      name: "Wrapped liquid staked Ether 2.0 (Wormhole)",
      token: "terra133chr09wu8sakfte5v7vd8qzq9vghtkv4tn0ur",
      icon: "https://static.lido.fi/wstETH/wstETH.png"
    },
    terra1t9ul45l7m6jw6sxgvnp8e5hj8xzkjsg82g84ap: {
      protocol: "Wormhole",
      symbol: "wsstSOL",
      name: "Lido staked SOL (Wormhole)",
      token: "terra1t9ul45l7m6jw6sxgvnp8e5hj8xzkjsg82g84ap",
      icon: "https://static.lido.fi/stSOL/stSOL.png",
      decimals: 8
    },
    terra1jxypgnfa07j6w92wazzyskhreq2ey2a5crgt6z: {
      protocol: "Wormhole",
      symbol: "whLDO",
      name: "Lido DAO Token (Wormhole)",
      token: "terra1jxypgnfa07j6w92wazzyskhreq2ey2a5crgt6z",
      icon: "https://static.lido.fi/LDO/LDO.png",
      decimals: 8
    },
    terra1yg3j2s986nyp5z7r2lvt0hx3r0lnd7kwvwwtsc: {
      protocol: "Lido",
      symbol: "stLuna",
      name: "Lido staked Luna",
      token: "terra1yg3j2s986nyp5z7r2lvt0hx3r0lnd7kwvwwtsc",
      icon: "https://static.lido.fi/stLUNA/stLUNA.png",
      decimals: 6
    },
    terra169edevav3pdrtjcx35j6pvzuv54aevewar4nlh: {
      protocol: "Wormhole",
      symbol: "XDEFI",
      name: "XDEFI (Wormhole)",
      token: "terra169edevav3pdrtjcx35j6pvzuv54aevewar4nlh",
      icon: "https://github.com/sushiswap/assets/blob/master/blockchains/ethereum/assets/0x72B886d09C117654aB7dA13A14d603001dE0B777/logo.png?raw=true",
      decimals: 8
    },
    terra1a7zxk56c72elupp7p44hn4k94fsvavnhylhr6h: {
      protocol: "Mars",
      symbol: "MARS",
      name: "Mars",
      token: "terra1a7zxk56c72elupp7p44hn4k94fsvavnhylhr6h",
      icon: "https://marsprotocol.io/mars_logo_colored.svg",
      decimals: 6
    },
    terra193c42lfwmlkasvcw22l9qqzc5q2dx208tkd7wl: {
      protocol: "Bitlocus",
      symbol: "BTL",
      name: "Bitlocus Token",
      token: "terra193c42lfwmlkasvcw22l9qqzc5q2dx208tkd7wl",
      icon: "https://bitlocus.com/assets/btl-token.png",
      decimals: 6
    },
    terra17y9qkl8dfkeg4py7n0g5407emqnemc3yqk5rup: {
      protocol: "Stader",
      symbol: "LunaX",
      name: "LunaX Token",
      token: "terra17y9qkl8dfkeg4py7n0g5407emqnemc3yqk5rup",
      icon: "https://raw.githubusercontent.com/stader-labs/assets/main/terra/LunaX_1.png",
      decimals: 6
    },
    terra1m3tdguf59xq3pa2twk5fjte5g6szj5y9x5npy7: {
      protocol: "LUNI",
      symbol: "LUNI",
      name: "LUNI",
      token: "terra1m3tdguf59xq3pa2twk5fjte5g6szj5y9x5npy7",
      icon: "https://d2s3n99uw51hng.cloudfront.net/static/100_100_LUNI_logo.png",
      decimals: 6
    },
    terra13awdgcx40tz5uygkgm79dytez3x87rpg4uhnvu: {
      protocol: "PlayNity",
      symbol: "PLY",
      name: "PlayNity Token",
      token: "terra13awdgcx40tz5uygkgm79dytez3x87rpg4uhnvu",
      icon: "https://playnity.io/uploads/logo/logo100.png",
      decimals: 6
    },
    terra1u2k0nkenw0p25ljsr4ksh7rxm65y466vkdewwj: {
      protocol: "TerraFloki",
      symbol: "TFLOKI",
      name: "TFLOKI",
      token: "terra1u2k0nkenw0p25ljsr4ksh7rxm65y466vkdewwj",
      icon: "https://terrafloki.io/terrafloki_logo.png",
      decimals: 6
    },
    terra1a8k3jyv3wf6k3zngza5h6srrxcckdf7zv90p6u: {
      protocol: "TerraFloki",
      symbol: "TFTIC",
      name: "TFLOKI Genesis Ticket",
      token: "terra1a8k3jyv3wf6k3zngza5h6srrxcckdf7zv90p6u",
      icon: "https://terrafloki.io/ticket_logo.png",
      decimals: 6
    },
    terra1xt9fgu7965kgvunnjts9zkprd8986kcc444q86: {
      protocol: "TerraFloki",
      symbol: "TFTICII",
      name: "TFLOKI New World Ticket",
      token: "terra1xt9fgu7965kgvunnjts9zkprd8986kcc444q86",
      icon: "https://terrafloki.io/ticket2_logo.png",
      decimals: 6
    },
    terra1vte2xv7dr8sfnrnwdf9arcyprqgr0hty5ads28: {
      protocol: "TerraFloki",
      symbol: "TFTICIII",
      name: "TFLOKI Aviator Ticket",
      token: "terra1vte2xv7dr8sfnrnwdf9arcyprqgr0hty5ads28",
      icon: "https://terrafloki.io/ticket3_logo.png",
      decimals: 6
    },
    terra1hmxxq0y8h79f3228vs0czc4uz5jdgjt0appp26: {
      protocol: "Moon",
      symbol: "MOON",
      name: "Moon Token",
      token: "terra1hmxxq0y8h79f3228vs0czc4uz5jdgjt0appp26",
      icon: "https://www.terra-moon.com/logo.png",
    },
    terra1xj49zyqrwpv5k928jwfpfy2ha668nwdgkwlrg3: {
      protocol: "Astroport",
      symbol: "ASTRO",
      name: "Astroport Token",
      token: "terra1xj49zyqrwpv5k928jwfpfy2ha668nwdgkwlrg3",
      icon: "https://astroport.fi/astro_logo.png",
    },
    terra1w8kvd6cqpsthupsk4l0clwnmek4l3zr7c84kwq: {
      protocol: "Angel Protocol",
      symbol: "HALO",
      name: "Angel Protocol Token",
      token: "terra1w8kvd6cqpsthupsk4l0clwnmek4l3zr7c84kwq",
      icon: "https://angelprotocol.io/favicon.png",
      decimals: 6,
    },
    terra1kdfsdm3c4reun9j3m4mk3nmyw4a4ns7mj24q3j: {
      protocol: "AstroPug",
      symbol: "PUG",
      name: "AstroPug Token",
      token: "terra1kdfsdm3c4reun9j3m4mk3nmyw4a4ns7mj24q3j",
      icon: "https://raw.githubusercontent.com/astropug/astropug-world/master/logo.png",
      decimals: 6,
    },
    terra1hnezwjqlhzawcrfysczcxs6xqxu2jawn729kkf: {
      protocol: "Orne",
      symbol: "ORNE",
      name: "Orne Token",
      token: "terra1hnezwjqlhzawcrfysczcxs6xqxu2jawn729kkf",
      icon: "https://orne.io/img/token_icon.png",
      decimals: 6
    },
  },
  testnet: {
    terra1em8dvzln8quptj9tuptf8tu73jyuv5jn6kqdwv: {
      protocol: "Nexus",
      symbol: "nETH",
      name: "Nexus nETH Token",
      token: "terra1em8dvzln8quptj9tuptf8tu73jyuv5jn6kqdwv",
      icon: "https://extraterra-assets.s3.us-east-2.amazonaws.com/images/SVG/nETH.svg"
    },
    terra18nle009rtynpjgleh2975rleu5zts0zdtqryte: {
      protocol: "Nexus",
      symbol: "Psi",
      name: "Nexus Governance Token",
      token: "terra18nle009rtynpjgleh2975rleu5zts0zdtqryte",
      icon: "https://terra.nexusprotocol.app/assets/psi.png"
    },
    terra16t7x97wuckxm5h927jygjfrt3tcwrzh3u2rlqm :{
      protocol: "SAYVE",
      symbol: "SAYVE",
      name: "SAYVE",
      token: "terra16t7x97wuckxm5h927jygjfrt3tcwrzh3u2rlqm",
      icon: "	https://extraterra-assets.s3.us-east-2.amazonaws.com/images/SAYVE100.png",
      decimals: 6
    },
    terra1qs7h830ud0a4hj72yr8f7jmlppyx7z524f7gw6:{
      protocol: "Mars",
      symbol: "MARS",
      name: "Mars",
      token: "terra1qs7h830ud0a4hj72yr8f7jmlppyx7z524f7gw6",
      icon: "https://marsprotocol.io/mars_logo_colored.svg",
      decimals: 6
    },
    terra1a0dx9xeh7sh6amn69zxg35twvdm44cghrlh87d :{
      protocol: "Apollo DAO Token",
        symbol: "APOLLO",
        token: "terra1a0dx9xeh7sh6amn69zxg35twvdm44cghrlh87d",
        icon: "https://extraterra-assets.s3.us-east-2.amazonaws.com/images/APOLLO60.png"
    },
    terra1u0t35drzyy0mujj8rkdyzhe264uls4ug3wdp3x:{
      protocol: "Anchor",
      symbol: "bLUNA",
      token: "terra1u0t35drzyy0mujj8rkdyzhe264uls4ug3wdp3x",
      icon: "https://whitelist.anchorprotocol.com/logo/bLUNA.png"
    },
    terra1azu2frwn9a4l6gl5r39d0cuccs4h7xlu9gkmtd:{
      protocol: "Kujira",
      symbol: "KUJI",
      name: "Kujira Token",
      token: "terra1azu2frwn9a4l6gl5r39d0cuccs4h7xlu9gkmtd",
      icon: "https://assets.kujira.app/kuji.png"
    },
    uusd: {
      protocol: "Native",
      symbol: "UST",
      token: "uusd",
      icon: 'https://assets.terra.money/icon/60/UST.png'
    },
    uluna: {
        protocol: "Native",
        symbol: "LUNA",
        token: "uluna",
        icon: 'https://assets.terra.money/icon/60/Luna.png'
    },
    terra1jqcw39c42mf7ngq4drgggakk3ymljgd3r5c3r5:{
      protocol: "Astroport",
      symbol: "ASTRO",
      name: "Astroport Token",
      token: "terra1jqcw39c42mf7ngq4drgggakk3ymljgd3r5c3r5",
      icon: "https://astroport.fi/astro_logo.png",
    },
    terra1v000amr8a59r88p33ec2kk9xqe047g7zzqqaf4: {
      protocol: "LoTerra",
      symbol: "LOTA",
      token: "terra1v000amr8a59r88p33ec2kk9xqe047g7zzqqaf4",
      icon: "https://loterra.io/LOTA.png"
    },
    terra1u0t35drzyy0mujj8rkdyzhe264uls4ug3wdp3x: {
      protocol: "Anchor",
      symbol: "bLUNA",
      token: "terra1u0t35drzyy0mujj8rkdyzhe264uls4ug3wdp3x",
      icon: "https://whitelist.anchorprotocol.com/logo/bLUNA.png"
    },
    terra19mkj9nec6e3y5754tlnuz4vem7lzh4n0lc2s3l: {
      protocol: "Anchor",
      symbol: "bETH",
      token: "terra19mkj9nec6e3y5754tlnuz4vem7lzh4n0lc2s3l",
      icon: "https://whitelist.anchorprotocol.com/logo/bETH.png"
    },
    terra1ajt556dpzvjwl0kl5tzku3fc3p3knkg9mkv8jl: {
      protocol: "Anchor",
      symbol: "aUST",
      token: "terra1ajt556dpzvjwl0kl5tzku3fc3p3knkg9mkv8jl",
      icon: "https://whitelist.anchorprotocol.com/logo/aUST.png"
    },
    terra1747mad58h0w4y589y3sk84r5efqdev9q4r02pc: {
      protocol: "Anchor",
      symbol: "ANC",
      token: "terra1747mad58h0w4y589y3sk84r5efqdev9q4r02pc",
      icon: "https://whitelist.anchorprotocol.com/logo/ANC.png"
    },
    terra10llyp6v3j3her8u3ce66ragytu45kcmd9asj3u: {
      protocol: "Mirror",
      symbol: "MIR",
      name: "Mirror",
      token: "terra10llyp6v3j3her8u3ce66ragytu45kcmd9asj3u",
      icon: "https://whitelist.mirror.finance/icon/MIR.png"
    },
    terra16vfxm98rxlc8erj4g0sj5932dvylgmdufnugk0: {
      protocol: "Mirror",
      symbol: "mAAPL",
      name: "Apple Inc.",
      token: "terra16vfxm98rxlc8erj4g0sj5932dvylgmdufnugk0",
      icon: "https://whitelist.mirror.finance/icon/AAPL.png"
    },
    terra1avryzxnsn2denq7p2d7ukm6nkck9s0rz2llgnc: {
      protocol: "Mirror",
      symbol: "mABNB",
      name: "Airbnb Inc.",
      token: "terra1avryzxnsn2denq7p2d7ukm6nkck9s0rz2llgnc",
      icon: "https://whitelist.mirror.finance/icon/ABNB.png"
    },
    terra1zeyfhurlrun6sgytqfue555e6vw2ndxt2j7jhd: {
      protocol: "Mirror",
      symbol: "mAMC",
      name: "AMC Entertainment Holdings Inc.",
      token: "terra1zeyfhurlrun6sgytqfue555e6vw2ndxt2j7jhd",
      icon: "https://whitelist.mirror.finance/icon/AMC.png"
    },
    terra12saaecsqwxj04fn0jsv4jmdyp6gylptf5tksge: {
      protocol: "Mirror",
      symbol: "mAMZN",
      name: "Amazon.com, Inc.",
      token: "terra12saaecsqwxj04fn0jsv4jmdyp6gylptf5tksge",
      icon: "https://whitelist.mirror.finance/icon/AMZN.png"
    },
    terra1qk0cd8576fqf33paf40xy3rt82p7yluwtxz7qx: {
      protocol: "Mirror",
      symbol: "mARKK",
      name: "ARK Innovation ETF",
      token: "terra1qk0cd8576fqf33paf40xy3rt82p7yluwtxz7qx",
      icon: "https://whitelist.mirror.finance/icon/ARKK.png"
    },
    terra15dr4ah3kha68kam7a907pje9w6z2lpjpnrkd06: {
      protocol: "Mirror",
      symbol: "mBABA",
      name: "Alibaba Group Holding Limited",
      token: "terra15dr4ah3kha68kam7a907pje9w6z2lpjpnrkd06",
      icon: "https://whitelist.mirror.finance/icon/BABA.png"
    },
    terra1csr22xvxs6r3gkjsl7pmjkmpt39mwjsrm0e2r8: {
      protocol: "Mirror",
      symbol: "mBTC",
      name: "Bitcoin",
      token: "terra1csr22xvxs6r3gkjsl7pmjkmpt39mwjsrm0e2r8",
      icon: "https://whitelist.mirror.finance/icon/BTC.png"
    },
    terra1qre9crlfnulcg0m68qqywqqstplgvrzywsg3am: {
      protocol: "Mirror",
      symbol: "mCOIN",
      name: "Coinbase Global, Inc.",
      token: "terra1qre9crlfnulcg0m68qqywqqstplgvrzywsg3am",
      icon: "https://whitelist.mirror.finance/icon/COIN.png"
    },
    terra1ys4dwwzaenjg2gy02mslmc96f267xvpsjat7gx: {
      protocol: "Mirror",
      symbol: "mETH",
      name: "Ethereum",
      token: "terra1ys4dwwzaenjg2gy02mslmc96f267xvpsjat7gx",
      icon: "https://whitelist.mirror.finance/icon/ETH.png"
    },
    terra14gq9wj0tt6vu0m4ec2tkkv4ln3qrtl58lgdl2c: {
      protocol: "Mirror",
      symbol: "mFB",
      name: "Facebook Inc.",
      token: "terra14gq9wj0tt6vu0m4ec2tkkv4ln3qrtl58lgdl2c",
      icon: "https://whitelist.mirror.finance/icon/FB.png"
    },
    terra104tgj4gc3pp5s240a0mzqkhd3jzkn8v0u07hlf: {
      protocol: "Mirror",
      symbol: "mGME",
      name: "GameStop Corp",
      token: "terra104tgj4gc3pp5s240a0mzqkhd3jzkn8v0u07hlf",
      icon: "https://whitelist.mirror.finance/icon/GME.png"
    },
    terra1qg9ugndl25567u03jrr79xur2yk9d632fke3h2: {
      protocol: "Mirror",
      symbol: "mGOOGL",
      name: "Alphabet Inc.",
      token: "terra1qg9ugndl25567u03jrr79xur2yk9d632fke3h2",
      icon: "https://whitelist.mirror.finance/icon/GOOGL.png"
    },
    terra13myzfjdmvqkama2tt3v5f7quh75rv78w8kq6u6: {
      protocol: "Mirror",
      symbol: "mGS",
      name: "Goldman Sachs Group Inc.",
      token: "terra13myzfjdmvqkama2tt3v5f7quh75rv78w8kq6u6",
      icon: "https://whitelist.mirror.finance/icon/GS.png"
    },
    terra19dl29dpykvzej8rg86mjqg8h63s9cqvkknpclr: {
      protocol: "Mirror",
      symbol: "mIAU (Delisted)",
      name: "iShares Gold Trust",
      token: "terra19dl29dpykvzej8rg86mjqg8h63s9cqvkknpclr",
      icon: "https://whitelist.mirror.finance/icon/IAU.png"
    },
    terra1p50j2k5vyw3q2tgywqvxyz59z8csh9p7x8dk5m: {
      protocol: "Mirror",
      symbol: "mIAU",
      name: "iShares Gold Trust",
      token: "terra1p50j2k5vyw3q2tgywqvxyz59z8csh9p7x8dk5m",
      icon: "https://whitelist.mirror.finance/icon/IAU.png"
    },
    terra1qhkjjlqq2lyf2evzserdaqx55nugksjqdpxvru: {
      protocol: "Mirror",
      symbol: "mKSU",
      name: "Kansas City Southern",
      token: "terra1qhkjjlqq2lyf2evzserdaqx55nugksjqdpxvru",
      icon: "https://whitelist.mirror.finance/icon/KSU.png"
    },
    terra1374w7fkm7tqhd9dt2r5shjk8ly2kum443uennt: {
      protocol: "Mirror",
      symbol: "mKSU (Delisted)",
      name: "Kansas City Southern",
      token: "terra1374w7fkm7tqhd9dt2r5shjk8ly2kum443uennt",
      icon: "https://whitelist.mirror.finance/icon/KSU.png"
    },
    terra1anw5z9u5l35vxhhqljuygmkupwmafcv0m86kum: {
      protocol: "Mirror",
      symbol: "mLRMR",
      name: "Larimar Therapeutics, Inc.",
      token: "terra1anw5z9u5l35vxhhqljuygmkupwmafcv0m86kum",
      icon: "https://whitelist.mirror.finance/icon/LRMR.png"
    },
    terra19jdmle3zl99gugmptx8auckc9c2xw7nspyxjvx: {
      protocol: "Mirror",
      symbol: "mLRMR (Delisted)",
      name: "Larimar Therapeutics, Inc.",
      token: "terra19jdmle3zl99gugmptx8auckc9c2xw7nspyxjvx",
      icon: "https://whitelist.mirror.finance/icon/LRMR.png"
    },
    terra12s2h8vlztjwu440khpc0063p34vm7nhu25w4p9: {
      protocol: "Mirror",
      symbol: "mMSFT",
      name: "Microsoft Corporation",
      token: "terra12s2h8vlztjwu440khpc0063p34vm7nhu25w4p9",
      icon: "https://whitelist.mirror.finance/icon/MSFT.png"
    },
    terra1djnlav60utj06kk9dl7defsv8xql5qpryzvm3h: {
      protocol: "Mirror",
      symbol: "mNFLX",
      name: "Netflix, Inc.",
      token: "terra1djnlav60utj06kk9dl7defsv8xql5qpryzvm3h",
      icon: "https://whitelist.mirror.finance/icon/NFLX.png"
    },
    terra18yx7ff8knc98p07pdkhm3u36wufaeacv47fuha: {
      protocol: "Mirror",
      symbol: "mQQQ",
      name: "Invesco QQQ Trust",
      token: "terra18yx7ff8knc98p07pdkhm3u36wufaeacv47fuha",
      icon: "https://whitelist.mirror.finance/icon/QQQ.png"
    },
    terra14vmf4tzg23fxnt9q5wavlp4wtvzzap82hdq402: {
      protocol: "Mirror",
      symbol: "mRXT (Delisted)",
      name: "Rackspace Technology, Inc.",
      token: "terra14vmf4tzg23fxnt9q5wavlp4wtvzzap82hdq402",
      icon: "https://whitelist.mirror.finance/icon/RXT.png"
    },
    terra1hvmzhnhxnyhjfnctntnn49a35w6hvygmxvjt7q: {
      protocol: "Mirror",
      symbol: "mRXT",
      name: "Rackspace Technology, Inc.",
      token: "terra1hvmzhnhxnyhjfnctntnn49a35w6hvygmxvjt7q",
      icon: "https://whitelist.mirror.finance/icon/RXT.png"
    },
    terra1fdkfhgk433tar72t4edh6p6y9rmjulzc83ljuw: {
      protocol: "Mirror",
      symbol: "mSLV",
      name: "iShares Silver Trust",
      token: "terra1fdkfhgk433tar72t4edh6p6y9rmjulzc83ljuw",
      icon: "https://whitelist.mirror.finance/icon/SLV.png"
    },
    terra15t9afkpj0wnh8m74n8n2f8tspkn7r65vnru45s: {
      protocol: "Mirror",
      symbol: "mSPY",
      name: "SPDR S&P 500",
      token: "terra15t9afkpj0wnh8m74n8n2f8tspkn7r65vnru45s",
      icon: "https://whitelist.mirror.finance/icon/SPY.png"
    },
    terra1nslem9lgwx53rvgqwd8hgq7pepsry6yr3wsen4: {
      protocol: "Mirror",
      symbol: "mTSLA",
      name: "Tesla, Inc.",
      token: "terra1nslem9lgwx53rvgqwd8hgq7pepsry6yr3wsen4",
      icon: "https://whitelist.mirror.finance/icon/TSLA.png"
    },
    terra1ax7mhqahj6vcqnnl675nqq2g9wghzuecy923vy: {
      protocol: "Mirror",
      symbol: "mTWTR",
      name: "Twitter, Inc.",
      token: "terra1ax7mhqahj6vcqnnl675nqq2g9wghzuecy923vy",
      icon: "https://whitelist.mirror.finance/icon/TWTR.png"
    },
    terra1fucmfp8x4mpzsydjaxyv26hrkdg4vpdzdvf647: {
      protocol: "Mirror",
      symbol: "mUSO",
      name: "United States Oil Fund, LP",
      token: "terra1fucmfp8x4mpzsydjaxyv26hrkdg4vpdzdvf647",
      icon: "https://whitelist.mirror.finance/icon/USO.png"
    },
    terra1z0k7nx0vl85hwpv3e3hu2cyfkwq07fl7nqchvd: {
      protocol: "Mirror",
      symbol: "mVIXY (Delisted)",
      name: "ProShares VIX Short-Term Futures ETF",
      token: "terra1z0k7nx0vl85hwpv3e3hu2cyfkwq07fl7nqchvd",
      icon: "https://whitelist.mirror.finance/icon/VIXY.png"
    },
    terra18gphn8r437p2xmjpw7a79hgsglf5y4t0x7s5ee: {
      protocol: "Mirror",
      symbol: "mVIXY",
      name: "ProShares VIX Short-Term Futures ETF",
      token: "terra18gphn8r437p2xmjpw7a79hgsglf5y4t0x7s5ee",
      icon: "https://whitelist.mirror.finance/icon/VIXY.png"
    },
    terra18py95akdje8q8aaukhx65dplh9342m0j884wt4: {
      protocol: "Mirror",
      symbol: "mWVFC (Delisted)",
      name: "WVS Financial Corp.",
      token: "terra18py95akdje8q8aaukhx65dplh9342m0j884wt4",
      icon: "https://whitelist.mirror.finance/icon/WVFC.png"
    },
    terra1090l5p5v794dpyzr07da72cyexhuc4zag5cuer: {
      protocol: "Mirror",
      symbol: "mWVFC",
      name: "WVS Financial Corp.",
      token: "terra1090l5p5v794dpyzr07da72cyexhuc4zag5cuer",
      icon: "https://whitelist.mirror.finance/icon/WVFC.png"
    },
    terra1jr9s6cx4j637fctkvglrclvrr824vu3r2rrvj7: {
      protocol: "metal",
      symbol: "METAL",
      name: "M-Metal",
      token: "terra1jr9s6cx4j637fctkvglrclvrr824vu3r2rrvj7",
      icon: "https://60b35461c99fd93a726e2e33--nifty-albattani-ec3bbd.netlify.app/_.png",
      decimals: 0
    },
    terra1jyunclt6juv6g7rw0dltlr0kgaqtpq4quvyvu3: {
      protocol: "Digipharm",
      symbol: "DPH",
      name: "Digipharm",
      token: "terra1jyunclt6juv6g7rw0dltlr0kgaqtpq4quvyvu3",
      icon: "https://digipharm.io/wp-content/uploads/2021/02/digipharm_etherscan_logo.png"
    },
    terra1lqm5tutr5xcw9d5vc4457exa3ghd4sr9mzwdex: {
      protocol: "Pylon",
      symbol: "MINE",
      name: "Pylon MINE Token",
      token: "terra1lqm5tutr5xcw9d5vc4457exa3ghd4sr9mzwdex",
      icon: "https://assets.pylon.rocks/logo/MINE.png"
    },
    terra1kvsxd94ue6f4rtchv2l6me5k07uh26s7637cza: {
      protocol: "Spectrum",
      symbol: "SPEC",
      name: "Spectrum Token",
      token: "terra1kvsxd94ue6f4rtchv2l6me5k07uh26s7637cza",
      icon: "https://terra.spec.finance/assets/SPEC.png"
    },
    terra1s8s39cnse493rzkmyr95esa44chc6vgztdm7gh: {
      protocol: "LOOP",
      symbol: "LOOP",
      name: "LOOP token",
      token: "terra1s8s39cnse493rzkmyr95esa44chc6vgztdm7gh",
      icon: "https://loop.markets/token/logo2.png"
    },
    terra1ykagvyzdmj3jcxkhavy7l84qs66haf7akqfrkc: {
      protocol: "LOOP",
      symbol: "LOOPR",
      name: "LOOPR token",
      token: "terra1ykagvyzdmj3jcxkhavy7l84qs66haf7akqfrkc",
      icon: "https://loop.markets/token/logo3.png"
    },
    terra1juwtwk5qymhz7s3hn0vx6tkqst54ud6wfjknvp: {
      protocol: "StarTerra",
      symbol: "STT",
      name: "StarTerra Token",
      token: "terra1juwtwk5qymhz7s3hn0vx6tkqst54ud6wfjknvp",
      icon: "https://starterra.io/assets/100x100_starterra.png"
    },
    terra18xfqhtfaz2su55zmurmh02ng9lnhw0xnyplvln: {
      protocol: "TerraWorld",
      symbol: "TWD",
      name: "TWD Token",
      token: "terra18xfqhtfaz2su55zmurmh02ng9lnhw0xnyplvln",
      icon: "https://terraoffice.world/twd_logo.png"
    },
    terra1eq0xqc88ceuvw2ztz2a08200he8lrgvnplrcst: {
      protocol: "Chainlink",
      symbol: "LINK",
      name: "ChainLink Token",
      token: "terra1eq0xqc88ceuvw2ztz2a08200he8lrgvnplrcst",
      icon: "https://uploads-ssl.webflow.com/5f6b7190899f41fb70882d08/61570c3748badb80a6920f49_LINK.png"
    },
    terra1azu2frwn9a4l6gl5r39d0cuccs4h7xlu9gkmtd: {
      protocol: "Kujira",
      symbol: "KUJI",
      name: "Kujira Token",
      token: "terra1azu2frwn9a4l6gl5r39d0cuccs4h7xlu9gkmtd",
      icon: "https://assets.kujira.app/kuji.png"
    },
    terra183cvzy6knvva6mpvzcq86uyfxw0nd4925m0d0m: {
      protocol: "White Whale",
      symbol: "WHALE",
      name: "Whale Token",
      token: "terra183cvzy6knvva6mpvzcq86uyfxw0nd4925m0d0m",
      icon: "https://www.whitewhale.money/tokenlogo.png",
      decimals: 6
    },
    terra1zzthh0mxnm7yhkp79sqfmh9vd3p93d5tzkk7zj: {
      protocol: "TerraFloki",
      symbol: "TFLOKI",
      name: "TFLOKI",
      token: "terra1zzthh0mxnm7yhkp79sqfmh9vd3p93d5tzkk7zj",
      icon: "https://terrafloki.io/terrafloki_logo.png",
      decimals: 6
    },
    terra1xzrwvtrf5cycjunnkksfgdreyewxvng42n7cy7: {
      protocol: "TerraFloki",
      symbol: "TFTIC",
      name: "TFLOKI Genesis Ticket",
      token: "terra1xzrwvtrf5cycjunnkksfgdreyewxvng42n7cy7",
      icon: "https://terrafloki.io/ticket_logo.png",
      decimals: 6
    },
    terra1ymmyhewmty4zepeuylmfpfqtmnqa88gparwu3u: {
      protocol: "TerraFloki",
      symbol: "TFTICII",
      name: "TFLOKI New World Ticket",
      token: "terra1ymmyhewmty4zepeuylmfpfqtmnqa88gparwu3u",
      icon: "https://terrafloki.io/ticket2_logo.png",
      decimals: 6
    },
    terra1skhz70xtsjvmx29zp5tdrqu84amc7nzrlvw9st: {
      protocol: "Noks",
      symbol: "DASNOKU",
      name: "Nokscoin",
      token: "terra1skhz70xtsjvmx29zp5tdrqu84amc7nzrlvw9st",
      icon: "https://raw.githubusercontent.com/orbitalwolf/noks/main/logo.png",
      decimals: 6
    },
  }
}
